import React, { useEffect, useState } from 'react';
import './style.scss';

const Header = ({ isTheme, onToggleMode }: any) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  return (
    <>
      <header
        className={
          scroll
            ? isTheme == 'light'
              ? 'header background-light'
              : 'header background-dark'
            : 'header'
        }>
        <div className="container">
          <div className="main-header">
            <div className="left">
              <div className="box-img">
                {isTheme == 'light' ? (
                  <img src="./images/logo-light.png" alt="" />
                ) : (
                  <img src="./images/logo.png" alt="" />
                )}
              </div>
            </div>
            <div className="center">
              <div className="main-menu">
                <ul className="list-menu">
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#tarotx">
                      What's TarotX
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#features">
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#guardians">
                      Timeless Guardians
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#work">
                      How TarotX Works
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="row-login">
                {/* <button
                  className="btn-login"
                  style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
                  Login
                </button> */}
                {/* <button
                  className="btn-started"
                  style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
                  Get Started
                </button> */}
                <div className="box-img" onClick={() => onToggleMode()}>
                  {isTheme == 'light' ? (
                    <img src="./images/sun.png"></img>
                  ) : (
                    <img src="./images/moon.png"></img>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
