import React from 'react';
import './style.scss';

const Footer = ({ isTheme }: any) => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="main-footer">
            <div className="left">
              <div className="box-img">
                {isTheme == 'light' ? (
                  <img src="./images/logo-light.png" alt="" />
                ) : (
                  <img src="./images/logo.png" alt="" />
                )}
              </div>
              <div className="title">Join our newsletter</div>
              <div className="desc">Keep up to date with everything TarotX</div>
            </div>
            <div className="right">
              <div className="menu-footer">
                <ul className="list-menu">
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#">
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#">
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#">
                      How it works
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#">
                      Community
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: isTheme == 'light' ? '#000' : '#fff' }}
                      href="#">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="email-contact">
                <input type="text" placeholder="Enter your email" />
                <button className="btn-email">Subscribe</button>
              </div>
            </div>
          </div>
          <div className="policy">
            <div className="left">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms of Conditions</a>
            </div>
            <div className="right">
              <p>TarotX App, All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
