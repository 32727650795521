import React, { useState } from 'react';
import Header from '../component/common/header';
import Footer from '../component/common/footer';
import { TypeAnimation } from 'react-type-animation';
import './style.scss';
function hanlelOnClick(value: any) {
  switch (value) {
    case 'Facebook':
      window.open('https://www.facebook.com/tarotx.official/');
      break;
    case 'Twitter':
      window.open('https://x.com/TarotX_Official');
      break;
    case 'Telegram':
      window.open('https://t.me/tarotX_official');
      break;

    default:
      window.open('https://www.instagram.com/tarotx.official/');
      break;
  }
}

const HomePage = () => {
  const [isTheme, setisTheme] = useState<any>('light');
  const dataDestiny = [
    {
      name: 'Transparent Readings',
      des: 'Verified Readers with transparent profiles and user reviews.',
      imgDark: './images/svg-desc.png',
      imgLight: './images/des1.png',
    },
    {
      name: 'Virtual Tarot Cards',
      des: 'Interactive and immersive card drawing experience.',
      imgDark: './images/svg-desc.png',
      imgLight: './images/des1.png',
    },
    {
      name: 'NFT Ownership',
      des: 'Own, trade, and collect unique NFT Tarot cards.',
      imgDark: './images/svg-desc.png',
      imgLight: './images/des3.png',
    },
    {
      name: 'Instant Booking',
      des: 'Quickly book reputable Readers anytime, anywhere.',
      imgDark: './images/svg-desc.png',
      imgLight: './images/des4.png',
    },
    {
      name: 'Rewards System',
      des: 'Earn and use TarotX tokens for transactions and rewards.',
      imgDark: './images/svg-desc.png',
      imgLight: './images/des5.png',
    },
  ];

  const dataExpore = [
    {
      name: 'Reader Profiles',
      des: 'Detailed bios, introductory videos, and ratings.',
      imgDark: './images/g-1.png',
      imgLight: './images/g-1-light.png',
    },
    {
      name: 'Interactive Tarot Spreads',
      des: 'Virtual shuffling and card selection.',
      imgDark: './images/g-2.png',
      imgLight: './images/g-2-light.png',
    },
    {
      name: 'Marketplace',
      des: 'Buy, sell, and trade Tarot related items.',
      imgDark: './images/g-3.png',
      imgLight: './images/g-3-light.png',
    },
    {
      name: 'Blockchain Security',
      des: 'Ensuring transparent and secure transactions.',
      imgDark: './images/g-4.png',
      imgLight: './images/g-4-light.png',
    },
    {
      name: 'Token Integration',
      des: 'Use TarotX tokens within the platform.',
      imgDark: './images/g-5.png',
      imgLight: './images/g-5-light.png',
    },
  ];

  const dataSocial = [
    {
      name: 'Facebook',
      img: './images/s-1.png',
    },
    {
      name: 'Twitter',
      img: './images/s-2.png',
    },
    {
      name: 'Telegram',
      img: './images/s-3.png',
    },
    {
      name: 'Instagram',
      img: './images/s-4.png',
    },
  ];

  const dataUser = [
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      height: 'auto',
      img: './images/user-1.png',
    },
    {
      des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      height: 'auto/span 3',
      img: './images/user-1.png',
    },
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      height: 'auto',
      img: './images/user-1.png',
    },
    {
      des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",

      height: 'auto/span 3',
      img: './images/user-1.png',
    },
    {
      des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      height: 'auto/span 3',
      img: './images/user-1.png',
    },
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      height: 'auto/span 2',
      img: './images/user-1.png',
    },
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      height: 'auto/span 2',
      img: './images/user-1.png',
    },
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      height: 'auto/span 2',
      img: './images/user-1.png',
    },
    {
      des: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      height: 'auto/span 2',
      img: './images/user-1.png',
    },
  ];

  const onToggleMode = () => {
    setisTheme(isTheme === 'light' ? 'dark' : 'light');
  };
  return (
    <div
      className="main-layout"
      style={{ background: isTheme === 'light' ? '#fff' : '#030014' }}>
      <Header isTheme={isTheme} onToggleMode={onToggleMode} />
      <section className="feature-banner">
        {isTheme == 'dark' && (
          <div className="blur-header">
            <img className="treeLeaves" src="./images/BG-header.png" alt="" />
          </div>
        )}

        <div className="blur-box">
          {isTheme == 'light' ? (
            <img src="./images/Blur-light.png" alt="" />
          ) : (
            <img src="./images/Blur.png" alt="" />
          )}
        </div>
        <div className="container">
          <div className="main-banner">
            <div className="title">
              <TypeAnimation
                style={{
                  whiteSpace: 'pre-line',
                  height: '195px',
                  display: 'block',
                }}
                sequence={[
                  'Guarded',
                  1000,
                  `Guarded by a Mysterious Necklace , The Journey Begins`,
                  1000,
                  'Guarded',
                  1000,
                  `Guarded by a Mysterious Necklace , The Journey Begins`,
                  1000,
                ]}
                wrapper="span"
                speed={30}
                repeat={Infinity}
              />
            </div>
            <div className="button-mystery">
              <button className="btn-mystery">Discover the Mystery</button>
            </div>
            <div className="desc">COMING SOON</div>
            <div className="main-images-tarot">
              <div className="box-img-big">
                {isTheme == 'light' ? (
                  <img src="./images/img-main-light.png" alt="" />
                ) : (
                  <>
                    <img src="./images/img-main.png" alt="" />
                    <div className="box-img-small">
                      <img src="./images/img-small.png" alt="" />
                    </div>
                  </>
                )}

                <div className="box-img-gif">
                  <img src="./images/gif-main.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-destiny" id="tarotx">
        <div className="container">
          <div className="wrap-destiny">
            <div
              className="title"
              style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
              Own Your Destiny with TarotX
            </div>
            <div
              className="desc"
              style={{
                color: isTheme == 'light' ? 'rgba(0, 0, 0, 0.7)' : '#ffffffb2',
              }}>
              Experience the future of Tarot readings with NFTs and blockchain
              technology.
            </div>
            <div className="guide-wrap">
              <div className="columns">
                {dataDestiny.map((e, i) => (
                  <div key={i.toString()} className="colum w-4 destiny-item">
                    <div className="content-wrap">
                      <div className="icon">
                        <img
                          src={isTheme == 'light' ? e.imgLight : e.imgDark}
                          alt=""
                        />
                      </div>
                      <div
                        className="text"
                        style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
                        {e.name}
                      </div>
                      <div
                        className="txt"
                        style={{
                          color:
                            isTheme == 'light'
                              ? 'rgba(142, 142, 147, 1)'
                              : '#fff',
                        }}>
                        {e.des}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-explore" id="features">
        <div className="container">
          <div className="wrap-explore">
            <div
              className="title"
              style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
              Explore TarotX Features
            </div>
            <div
              className="desc"
              style={{
                color: isTheme == 'light' ? 'rgba(0, 0, 0, 0.7)' : '#ffffffb2',
              }}>
              Experience the future of Tarot readings with NFTs and blockchain
              technology.
            </div>
            <div className="guide-wrap-explore">
              <div className="columns">
                {dataExpore.map((e, i) => (
                  <div key={i.toString()} className="colum w-4">
                    <div className="guide-explore">
                      <div className="content-flip">
                        <div className="box-img">
                          <img
                            src={isTheme == 'light' ? e.imgLight : e.imgDark}
                            alt=""
                          />
                        </div>
                        <div className="line-one lines"></div>
                        <div className="line-two lines"></div>
                        <div className="line-three lines"></div>
                        <div className="line-four lines"></div>
                        <div
                          className="txt"
                          style={{
                            color: isTheme == 'light' ? '#000' : '#fff',
                          }}>
                          {e.name}
                        </div>
                        <div
                          className="desc-row"
                          style={{
                            color:
                              isTheme == 'light'
                                ? 'rgba(0, 0, 0, 0.7)'
                                : '#ffffffb2',
                          }}>
                          {e.des}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-tarot-work" id="work">
        {/* <div className="container">
          <div className="guide-tarot-work">
            <div className="left">
              <div className="title">How TarotX Works?</div>
            </div>
            <div className="right">
              <div className="wrap-line">
                <div className="vector-line">
                  <img src="./images/vector.png" alt="" />
                </div>
                <div className="item">
                  <div className="images-eclip">
                    <img src="./images/eclip.png" alt="" />
                  </div>
                  <div className="background-line"></div>
                  <div className="number">01</div>
                  <div className="txt">Create an Account</div>
                  <div className="desc">Sign up and set up your profile.</div>
                </div>
                <div className="item">
                  <div className="images-eclip">
                    <img src="./images/eclip.png" alt="" />
                  </div>
                  <div className="background-line"></div>
                  <div className="number">02</div>
                  <div className="txt">Explore Readers</div>
                  <div className="desc">
                    Browse and choose from a list of verified Readers.
                  </div>
                </div>
                <div className="item">
                  <div className="images-eclip">
                    <img src="./images/eclip.png" alt="" />
                  </div>
                  <div className="background-line"></div>
                  <div className="number">03</div>
                  <div className="txt">Book a Session</div>
                  <div className="desc">
                    Instantly book a reading at your convenience
                  </div>
                </div>
                <div className="item">
                  <div className="images-eclip">
                    <img src="./images/eclip.png" alt="" />
                  </div>
                  <div className="background-line"></div>
                  <div className="number">04</div>
                  <div className="txt">Own NFTs</div>
                  <div className="desc">
                    Purchase, collect, and trade unique Tarot cards.
                  </div>
                </div>
                <div className="item">
                  <div className="images-eclip">
                    <img src="./images/eclip.png" alt="" />
                  </div>
                  <div className="background-line"></div>
                  <div className="number">05</div>
                  <div className="txt">Earn Rewards</div>
                  <div className="desc">
                    Use TarotX tokens for various platform benefits.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <section className="section-guardians" id="guardians">
          <div className="container">
            <div className="guide-guardians">
              <div className="box-img">
                <img src="./images/BG-de.png" alt="" />
                <div className="img-center">
                  <img
                    className="treeLeaves"
                    src="./images/img-center.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="title">Timeless Guardians</div>
              <div className="desc">
                Join now to get exclusive early access to rare NFT Characters!
              </div>
              <div className="is-comming">COMING SOON</div>
            </div>
          </div>
        </section>
        <div className="title">HOW DOES TAROTX WORK ?</div>
        <div className="desc">
          Experience the future of Tarot readings with NFTs and blockchain
          technology.
        </div>
        <div className="container">
          <div className="card-item">
            <div className="card-item-big">
              <div className="box-img">
                <img src="./images/STEP1.png" />
              </div>
              <div className="box-img">
                <img src="./images/STEP2.png" />
              </div>
            </div>
            <div className="card-item-small">
              <div className="box-img">
                <img src="./images/STEP3.png" />
              </div>
              <div className="box-img">
                <img src="./images/STEP4.png" />
              </div>
              <div className="box-img">
                <img src="./images/STEP5.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-booking">
        <div className="container">
          <div className="guide-booking">
            <div className="left">
              <div className="title">Try new forms of visual storytelling</div>
            </div>
            <div className="right">
              <div className="desc">
                Tarot is like a role-playing game, where you map out scenarios
                in your own life. Every session is a conversation between you,
                the facilitator, and the evocative artwork. During a session,
                you can zoom in on the illustrations, read about the archetypes,
                and chime in with thoughts. Leave with actionable takeaways and
                clarity.
              </div>
              <div className="button-booking">
                <button className="btn-booking">
                  Book a session (Coming Soon)
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-userSay">
        <div
          className="title"
          style={{ color: isTheme == 'light' ? '#000' : '#fff' }}>
          WHAT OUR USERS SAY
        </div>
        <div
          className="desc"
          style={{
            color: isTheme == 'light' ? 'rgba(0, 0, 0, 0.7)' : '#ffffffb2',
          }}>
          Quotes and ratings from early adapters and satisfied users.
        </div>

        <div className="grid-container container">
          {dataUser.map((e, i) => (
            <div
              className={`grid-item`}
              style={{
                gridRow: `${e.height}`,
              }}>
              <div className="info-item">
                <div className="box-img">
                  <img src={e.img}></img>
                </div>
                <div className="info">
                  <span className="main-text">Guillermo Rauch</span>
                  <span className="sub-text">TarotX User</span>
                </div>
              </div>
              <span className="des">{e.des}</span>
            </div>
          ))}
        </div>
      </section> */}

      <section className="section-community">
        <div className="container">
          <div className="wrap-community">
            <div
              className="title"
              style={{
                color: isTheme == 'light' ? '#000' : '#fff',
              }}>
              Join Our Community
            </div>
            <div
              className="desc"
              style={{
                color: isTheme == 'light' ? 'rgba(0, 0, 0, 0.7)' : '#ffffffb2',
              }}>
              Be part of our vibrant community events and discussions.
            </div>
            <div className="guide-community">
              <div className="columns">
                {dataSocial.map((e, i) => (
                  <div
                    key={i.toString()}
                    className="colum w-3"
                    onClick={() => {
                      hanlelOnClick(e.name);
                    }}>
                    <div className="content-row">
                      <div className="item-social">
                        <div className="line-one lines"></div>
                        <div className="line-two lines"></div>
                        <div className="line-three lines"></div>
                        <div className="line-four lines"></div>
                        <div className="icon">
                          <img src={e.img} alt="" />
                        </div>
                        <div
                          className="text"
                          style={{
                            color: isTheme == 'light' ? '#000' : '#fff',
                          }}>
                          {e.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="box-img-footer">
        <img src="./images/images-bottom.png" alt="" />
      </div> */}
      <Footer isTheme={isTheme} />
    </div>
  );
};

export default HomePage;
