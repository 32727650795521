import React from "react";
import HomePage from "./homePage";
import "./App.css";

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
